<script setup lang="ts">
const globalStore = useGlobalStore();
const productStore = useProductStore();

const {product} = productStore;
const {options, selectedProduct, setQueriedProducts} = globalStore;
const {filterPop, queriedProducts, removedFromQuery} = storeToRefs(globalStore);

const {productSvgSource, matClass} = storeToRefs(productStore);
const {$activeModalsBus, $refreshAos} = useNuxtApp();
const matSelectionRef = ref(null);
const selectedColor = ref({label: "שחור", value: "black"});
const selectedBorderColor = ref({label: "שחור", value: "black"});
const productSelection = ref([]);
const showMaterialPop = ref(false);
const is_3d_mat = ref(false);
const selectedYear = computed(() => {
  const findYear = product?.years?.nodes.find((year, index) => {
    if (year?.databaseId == selectedProduct.year) {
      return year;
    }
    return year?.databaseId == parseInt(selectedProduct?.year);
  });
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  if (findYear?.name === 'NOW') {
    if (currentMonth < 7) {
      return currentYear;
    } else {
      return currentYear + 1;
    }
  }
  if (!findYear) {
    const yearRange = [_First(product?.years?.nodes), _Last(product?.years?.nodes)]
    const firstYear = yearRange[0]?.name;
    const lastYear = yearRange[1]?.name == 'NOW' ? (currentYear < 7) ? currentYear : currentYear + 1 : yearRange[1]?.name;
    return `${lastYear}-${firstYear}`;
  }
  return findYear?.name;
});
const selectTypes = ref(product.productFields?.selectionTypes);

const carAttributes = computed(() => {
  if (!_IsEmpty(selectedProduct.meta)) {
    return selectedProduct.meta;
  }
  if (!_IsEmpty(product?.globalAttributes) && !_IsEmpty(product?.globalAttributes?.nodes)) {
    const globalAttributes = product?.globalAttributes?.nodes;
    const attributes = [];
    for (const attribute of globalAttributes) {

      attributes.push({
        label: attribute.name,
        value: attribute.terms.nodes[0]?.name.toUpperCase(),
      });
    }
    return attributes;
  }

  return null;
});
const productCategoryID = computed(() => {
  if (!_IsEmpty(product.productCategories)) {
    return product?.productCategories?.nodes[0]?.databaseId;
  }
  return null;
});
if (productCategoryID.value == 1795) {
  is_3d_mat.value = true;
}

const colorSelect = (type: string, color: {}) => {
  switch (type) {
    case "color":
      selectedColor.value = color;
      if (_IsEmpty(selectedBorderColor.value)) {
        selectedBorderColor.value = {label: "שחור", value: "black"};
      }
      calculatePrice();
      break;
    case "border":
      selectedBorderColor.value = color;
      calculatePrice();
      break;
  }
};

const svgSelection = (type: string, select: boolean) => {

  const carSvgs = document.querySelectorAll(".product-svg svg");
  carSvgs.forEach(carSvg => {
    switch (type) {
      case "front-set":
        if (select) {
          carSvg?.querySelectorAll(`#row1`).forEach(item => item.classList.add("selected-path"))
          carSvg?.querySelectorAll(`#row2`).forEach(item => item.classList.add("selected-path"))
        } else {
          carSvg?.querySelectorAll(`#row1`).forEach(item => item.classList.remove("selected-path"))
          carSvg?.querySelectorAll(`#row2`).forEach(item => item.classList.remove("selected-path"))
        }
        break;
      case "row1":
        if (select) {
          carSvg?.querySelectorAll(`#row1`).forEach(item => item.classList.add("selected-path"))
          carSvg?.querySelectorAll(`#row2`).forEach(item => item.classList.remove("selected-path"))
        } else {
          carSvg?.querySelectorAll(`#row1`).forEach(item => item.classList.remove("selected-path"))
        }
        break;
      default:
        if (select) {
          carSvg?.querySelectorAll(`#${type}`).forEach(item => item.classList.add("selected-path"))
        } else {
          carSvg?.querySelectorAll(`#${type}`).forEach(item => item.classList.remove("selected-path"))
        }
        break;
    }
  })

};

function selectType(event, value) {
  console.log(event);
  for (let index = 0; index < matSelectionRef.value?.length; index++) {
    const element = matSelectionRef.value[index];


    if (value.type[0] == "front-set" && element.value == "row1") {
      element.checked = false;
    }
    if (value.type[0] == "row1" && element.value == "front-set") {
      element.checked = false;
    }
  }

  svgSelection(value.type[0], event.target.checked);


  productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);

  calculatePrice();
}

const calculatePrice = () => {
  let products = [];
  const items = matSelectionRef.value.filter((item) => item.checked).map((item: HTMLInputElement) => item);
  for (const selection of items) {
    const productId = product?.databaseId;
    const quantity = 1;
    const extraData = JSON.stringify({
      is_nuxt: true,
      car_year: selectedYear.value,
      mat_color: !_IsEmpty(selectedColor.value) ? (selectedColor.value) : "",
      mat_border_color: !_IsEmpty(selectedBorderColor.value) ? (selectedBorderColor.value) : "",
      selected_options: selection.value,
      selected_meta: (carAttributes.value),
    });
    const rawData = {
      is_nuxt: true,
      car_year: selectedYear.value,
      mat_color: selectedColor.value,
      mat_border_color: selectedBorderColor.value,
      selected_options: {value: selection.value, label: selection.dataset.selection_label},
      selected_meta: carAttributes.value,
    };
    const data = {
      productId,
      quantity,
      extraData,
    };
    products.push({
      price: selection.dataset.selection_price,
      data: data,
      quantity: quantity,
      product: product,
      rawData: rawData,
    });
  }
  $activeModalsBus.event("addProduct", products);
};

const cloneSvg = () => {
  const element = document.querySelector(".product-svg svg")?.cloneNode(true);

  return element;
};
const findSelectType = (type) => {
  return selectTypes.value.find((item) => item.type[0] == type);
};
const titleBreak = (title) => {
  let breaktitle = title.split(" ");
  let year_index = breaktitle.indexOf("שנים");
  breaktitle.splice(year_index, 0, "<br>");


  return breaktitle.join(" ");
};

onMounted(() => {
  const input: HTMLInputElement = matSelectionRef.value[0];
  setTimeout(() => {
    input.click();
    productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);

  }, 1000);
  setTimeout(() => {
    const carSvgs = document.querySelectorAll(".product-svg svg");
    carSvgs.forEach(carSvg => {
      const row1 = carSvg.querySelector(`#row1`);
      const row2 = carSvg.querySelector(`#row2`);
      const row3 = carSvg.querySelector(`#row3`);
      const trunk1 = carSvg.querySelector(`#trunk1`);
      const trunk2 = carSvg.querySelector(`#trunk2`);


      row1?.addEventListener('click', () => {
        const input: HTMLInputElement = document.querySelector(`.variation-radios input[value="row1"]`);
        const event = {target: input};
        if (input.checked) {
          input.checked = false;

        } else {
          input.checked = true;
        }
        event.target = input;
        selectType(event, findSelectType("row1"));
        productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);
      });
      row2?.addEventListener('click', () => {
        const input: HTMLInputElement = document.querySelector(`.variation-radios input[value="front-set"]`);
        const event = {target: input};

        if (input.checked) {
          input.checked = false;
          svgSelection("row2", false);
        } else {
          input.checked = true;
          svgSelection("front-set", true);

        }
        event.target = input;
        selectType(event, findSelectType("front-set"));
        productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);
      });
      row3?.addEventListener('click', () => {
        const input: HTMLInputElement = document.querySelector(`.variation-radios input[value="row3"]`);
        if (input.checked) {
          input.checked = false;
          svgSelection("row3", false);
        } else {
          input.checked = true;
          svgSelection("row3", true);
        }
        productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);
      });
      trunk2?.addEventListener('click', () => {
        const input: HTMLInputElement = document.querySelector(`.variation-radios input[value="trunk2"]`);
        const event = {target: input};

        if (input.checked) {
          input.checked = false;
          svgSelection("trunk2", false);
        } else {
          input.checked = true;
          svgSelection("trunk2", true);
        }
        selectType(event, findSelectType("trunk2"));
        productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);
      });
      trunk1?.addEventListener('click', () => {
        const input: HTMLInputElement = document.querySelector(`.variation-radios input[value="trunk1"]`);
        const event = {target: input};
        if (input.checked) {
          input.checked = false;
          svgSelection("trunk1", false);
        } else {
          input.checked = true;
          svgSelection("trunk1", true);
        }
        event.target = input;
        selectType(event, findSelectType("trunk1"));
        productSelection.value = matSelectionRef.value.filter((item) => item.checked).map((item) => item.value);
      });
    }, 1000);
  });
});

watchEffect(() => {
  if (process.client) {
    $refreshAos();
  }
});
//const coverImage = product?.makes?.nodes[0]?.parent?.node?.make?.bannerForProductPage;
</script>

<template>
  <div class="text-center mb-4 mx-auto relative">
    <strong> כל שטיח שיבחר, יעוצב עבורך באופן אישי!</strong>
    <div class="red-underline red-underline--short" data-aos="fade-left"></div>
  </div>
  <h2 class="mb-mb-5 mb-4 m100 title-h5">
    <span class="font-weight-bold" v-if="productCategoryID == 21"> שלב 1: יש לבחור סט שטיחים \ שטיח לתא מטען</span>
    <span class="font-weight-bold" v-else> שלב 1: סט שטיחים</span>
  </h2>
  <template v-if="!is_3d_mat">
    <div :class="'product-svg  no_mobile ' + matClass">
      <span v-html="productSvgSource"></span>
      <!--      <SvgImage :key="productSvgSource" :is-html="true" :img="productSvgSource" v-if="productSvgSource"></SvgImage>-->
    </div>


    <div class="text-center no_mobile">
      לא בערך, לא כמעט.
      <b>100% התאמה לדגם שלך</b>
    </div>
    <button @click.prevent="$activeModalsBus.event('openCarSvg', cloneSvg())" class="zoom-in-bt no_mobile mr-auto">
      <nuxt-img src="/images/svg/zoom-in.svg" alt=""/>
      מבט מקרוב
    </button>
  </template>

  <div class="add_to_cart">
    <div
        class="variation-radios" :class="{'mat-3d-radios border-0 my-0 py-2 justify-content-start' : is_3d_mat  }"
    >
      <div class="radio-wrap" v-for="item in selectTypes">
        <input
            @change="selectType($event, item)"
            type="checkbox"
            ref="matSelectionRef"
            name="mat_selection[]"
            :data-selection_price="item.price"
            :data-selection_label="item.type[1]"
            :value="item.type[0]"
        />
        <label>
          {{ item.type[1] }}
          <div class="price-wrap red-underline red-underline--short mb-md-0 mb-3">
            <span class="currency">₪</span>
            <span class="price text--black">{{ item.price }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
  <template v-if="!is_3d_mat">

    <div class="product-svg-mobile mobile_only">
      <div class="product-svg d-inline-block">
        <span v-html="productSvgSource"></span>
        <!--      <SvgImage :key="productSvgSource" :img="productSvgSource" :is-html="true" v-if="productSvgSource"></SvgImage>-->
      </div>
      <button @click.prevent="$activeModalsBus.event('openCarSvg', cloneSvg())" class="zoom-in-bt ">
        <nuxt-img src="/images/svg/zoom-in.svg" alt=""/>
        מבט מקרוב
      </button>
    </div>
  </template>

  <div :class="`mat-step ${productCategoryID != 21 ? 'd-none' : ''}`">
    <h2 class="mb-mb-5 mb-4 title-h5">
      <span class="font-weight-bold">שלב 2: ובאיזה צבעים נייצר אותם עבורך?</span>
    </h2>
    <div class="mat-select mat-color-select flex flex-row flex-wrap justify-content-md-between">
      <div class="col-12">
        <div class="step-text" v-if="productCategoryID != 21">
          צבע השטיח
          <span class="selected-color font-weight-bold fw-bold"> : שחור</span>
        </div>

        <div class="step-text" v-else>
          צבע השטיח:

          <span class="selected-color font-weight-bold fw-bold">{{
              _IsEmpty(selectedColor) ? "צבע הנבחר" : selectedColor.label
            }}</span>
        </div>
      </div>
      <div class="col">
        <div
            class="color-radios color-radios__mat_color flex flex-wrap mx-0 justify-content-md-center justify-content-start">
          <div
              v-for="(row, index) in options.matColors"
              :key="`${index}-${row.nameEn}`"
              :class="`radio-wrap mat-color ${productCategoryID != 21 ? 'active no_mobile' : ''}
                               ${row.nameEn == selectedColor.value ? 'active' : ''}`"
              :data-label="row.name"
              :data-value="row.nameEn"
              @click="colorSelect('color', { label: row.name, value: row.nameEn })"
          >
            <div
                :class="`border-round-select mat-color-${row.nameEn}`"
                :style="`background-image: url('${row.select?.node?.sourceUrl}');`"
            ></div>
          </div>
          <input type="hidden" name="mat-color" value="black"/>
        </div>
      </div>
    </div>
    <div class="mat-wrap flex flex-wrap justify-content-center">
      <span class="mat position-relative text-center">
        <nuxt-img loading="lazy" class="position-relative" style="opacity: 1" src="/images/mat-gumi-pic.png" alt=""/>

        <nuxt-img
            loading="lazy"
            v-for="(row, index) in options.matColors"
            :key="`${index}-${row.nameEn}`"
            :class="`mat-color mat-color-${row.nameEn} ${row.nameEn == 'black' ? ' active' : ''}
                            ${row.nameEn == selectedColor.value ? ' active' : ''}
                            `"
            :src="row.image.node.sourceUrl"
            :alt="row.image.node.altText"
        />

        <span class="mat-border">
          <nuxt-img
              loading="lazy"
              v-for="(row, index) in options.matBorderColors"
              :key="`${index}-${row.nameEn}`"
              :class="`mat-border mat-border-${row.nameEn} ${row.nameEn == 'black' ? ' active' : ''}
                              ${row.nameEn == selectedBorderColor?.value ? ' active' : ''}
                              `"
              :src="row.image?.node?.sourceUrl"
              :alt="row.image?.node?.altText"
          />
        </span>
      </span>
    </div>

    <div class="mat-select mat-border-select row justify-content-md-between">
      <div class="col-12">
        <div class="step-text" v-if="productCategoryID != 21">
          צבע המסגרת
          <span class="selected-color font-weight-bold"> : שחור</span>
        </div>

        <span class="step-text" v-else
        >צבע המסגרת:
          <span class="selected-color font-weight-bold fw-bold">{{
              _IsEmpty(selectedBorderColor) ? "צבע הנבחר" : selectedBorderColor?.label
            }}</span>
        </span>
      </div>
      <div class="col">
        <div
            :class="`color-radios color-radios__mat_color flex flex-wrap mx-0 ${
            productCategoryID != 21 ? 'justify-content-md-center' : 'justify-content-md-center'
          }  justify-content-start`"
        >
          <div
              v-for="(row, index) in options.matBorderColors"
              :key="`${index}-${row.nameEn}`"
              :class="`radio-wrap mat-border-color ${productCategoryID != 21 ? 'active no_mobile' : ''}

                              ${row.nameEn == selectedBorderColor.value ? 'active' : ''}
                              `"
              :data-label="row.name"
              :data-value="row.nameEn"
              @click="colorSelect('border', { label: row.name, value: row.nameEn })"
          >
            <div class="border-round-select" :style="`background-image: url('${row.select?.node?.sourceUrl}')`"></div>
          </div>

          <input type="hidden" name="mat-border-color" value="black"/>
        </div>
      </div>
    </div>
  </div>
  <van-popup teleport="body" v-model="showMaterialPop" position="bottom">
    <template #title>
      <div class="popup-title">פרטי המוצר</div>
    </template>
    <template #content>
      <div class="popup-content">
        <div class="popup-content__title">פרטי המוצר</div>
        <div class="popup-content__text">
          <div class="popup-content__text__title">סט שטיחים</div>
          <div class="popup-content__text__desc">
            <div class="popup-content__text__desc__title">צבע השטיח:</div>
            <div class="popup-content__text__desc__value">{{ selectedColor.label }}</div>
          </div>
          <div class="popup-content__text__desc">
            <div class="popup-content__text__desc__title">צבע המסגרת:</div>
            <div class="popup-content__text__desc__value">{{ selectedBorderColor.label }}</div>
          </div>
        </div>
      </div>
    </template>
  </van-popup>


</template>

<style scoped lang="scss">
.section {
  &--pad {
    padding: 0;
  }
}

.zoom-in-bt {
  display: flex;
  margin: 10px 0;
}

.mat-3d-radios .radio-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mat-3d-radios .variation-radios input[type="checkbox"] {
  width: 50px;
  height: 40px;
  border-radius: 10px;
  margin-left: 8px;
}
</style>
